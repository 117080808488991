import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import { useConfig } from '@/stores/config'
import type { RegionsEnvsConfig } from '@/types/regionEnvs'
import { nextTick } from 'vue'

const temp = (id: number, params: object) => `
(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
m[i].l=1*new Date();
for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
ym(${id}, "init",  ${JSON.stringify(params)})`

export class YandexMetrika {
  id = 0

  constructor (id: number) {
    this.id = id
  }

  hit (path: string, opts: any) {
    if (window.ym) {
      window.ym(this.id, 'hit', path, opts)
    }
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { currentRegion } = useConfig()
  const analyticsRegionsJson = config.public.regionsEnvsJson as unknown as RegionsEnvsConfig
  const id = analyticsRegionsJson[currentRegion.value.id]?.ymId as number

  if (isNaN(id)) {
    return
  }

  const content = temp(id, {
    accurateTrackBounce: true,
    defer: true,
    ecommerce: 'dataLayer'
  })
  const yandex = new YandexMetrika(id)
  const router = useRouter()

  router.afterEach((to, from) => {
    nextTick(() => {
      try {
        if (process.client) {
          const hasHash = (to.hash && to.hash !== '') || (from.hash && from.hash !== '')
          if (!hasHash) {
            yandex.hit(to.fullPath, { referer: document.referrer })
          }
        }
      } catch (error) {
        console.error('Yandex Metrika hit error:', error)
      }
    })
  })

  nuxtApp.hooks.hook('app:rendered', (ctx) => {
    const head = ctx.ssrContext?.head

    head?.push({
      script: [
        {
          type: 'text/javascript',
          innerHTML: content
        }
      ]
    })
  })

  return {
    provide: {
      yandex
    }
  }
})

declare global {
  interface Window {
    ym: any
  }
}
